/**
 * Common Talent User data
 * @typedef {Object} EventSlice
 * @property {number} id
 * @property {StringConstructor} title
 * @property {Array} achievement_roles
 */

/**
 *
 * @param {*} event
 * @returns {EventSlice}
 */
export const eventSlice = (event) => {
  if (!event) return;
  const { id, title, achievement_roles, start, end } = event;
  return {
    id,
    title,
    achievement_roles,
    start,
    end,
  };
};

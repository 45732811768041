<template>
  <div>
    <old-penalty-form
      :pending="penaltyPending"
      :penalty="oldPenalty"
      @onSubmit="handleSavePenalty"
    />
    <v-divider class="my-6"></v-divider>
    <div>
      <div class="d-flex mb-3 align-center">
        <div class="flex-grow-1 mr-2">
          <h3 class="text-h5 mb-0"><strong>Мероприятия трека</strong></h3>
          <div class="text-caption">Всего добавлено: {{ eventsTotal }}</div>
        </div>

        <v-btn
          class="flex-grow-0"
          color="primary"
          @click.prevent="handleOpenEventSettings(null)"
          >Добавить мероприятия</v-btn
        >
      </div>
      <v-alert
        v-if="!eventsTotal"
        color="red"
        dense
        type="error"
        @click="handleOpenEventSettings(null)"
      >
        Добавьте мероприятия
      </v-alert>

      <event-setting-dialog
        v-model="addEventDialog"
        :track="track"
        :current-setting="currentSetting"
        :event-id="selectedEventId"
        @addSetting="getEvents(events.page)"
        @updateSetting="getEvents(events.page)"
      />

      <div v-if="eventsTotal">
        <div class="mb-4">
          <v-text-field
            v-model="search"
            label="Поиск мероприятий трека"
            solo
            clearable
            :loading="searchLoading"
            prepend-inner-icon="mdi-magnify"
            hint="Введите название мероприятия или его ID"
            persistent-hint
            rounded
            @input="debouncedSearch"
          ></v-text-field>
        </div>
        <div v-if="!events.list.length && !searchLoading && search">
          по запросу {{ search }} мероприятий для трека {{ trackName }} не
          найдено
        </div>

        <div class="mb-4 text-right">
          <v-btn
            title="Сортировка по ID мероприятия"
            color="primary"
            text
            @click="handleSort('event_id')"
          >
            ID мероприятия<v-icon right>{{
              ordering === "event_id"
                ? "mdi-sort-ascending"
                : "mdi-sort-descending"
            }}</v-icon></v-btn
          >
        </div>
        <div>
          <event-settings-card
            v-for="item in events.list"
            :key="item.event_id"
            class="mb-4"
            :settings="item"
            :track="track"
            :old-penalty="oldPenalty"
          >
            <template #controls>
              <v-btn
                title="Редактировать настройки"
                icon
                @click="handleOpenEventSettings(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
              <v-btn
                title="Копировать настройки"
                icon
                @click="handleCopyEventSettings(item)"
                ><v-icon>mdi-file-multiple-outline</v-icon></v-btn
              >
              <v-btn
                title="Удалить настройки"
                icon
                @click="handleDeleteSetting(item)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </template>
          </event-settings-card>

          <app-pager
            :value="events.page"
            :total="events.count"
            :limit="events.size"
            @input="getEvents"
          ></app-pager>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import EventSettingDialog from "@/components/dialogs/EventSettingDialog";
import EventSettingsCard from "@/components/EventSettingsCard";
import OldPenaltyForm from "./OldPenaltyForm";
import { talentClient } from "@/api";

import debounce from "lodash/debounce";
// import TrackEventSearch from "@/components/event/TrackEventSearch";
export default {
  name: "EventsSettingSection",
  components: {
    EventSettingDialog,
    EventSettingsCard,
    OldPenaltyForm,
    // TrackEventSearch,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addEventDialog: false,
      penaltyPending: true,
      oldPenalty: {},
      events: {
        page: 1,
        count: 0,
        size: 40,
        list: [],
      },
      eventsTotal: 0,
      currentSetting: null,
      selectedEventId: undefined,
      search: "",
      searchLoading: false,
      event_ids: undefined,
      ordering: "-event_id",
    };
  },
  computed: {
    trackName() {
      return this?.track?.track.name;
    },
  },
  created() {
    this.getEventTrackInfo();
    this.debouncedSearch = debounce(this.handleSearch, 500);
  },
  methods: {
    async getEvents(page = 1) {
      const { events, event_ids } = this;
      const params = {
        limit: events.size,
        offset: (page - 1) * events.size,
        ordering: this.ordering,
        ...(event_ids && { event_ids }),
      };
      const { data } = await apiClient({
        method: "GET",
        url: `/tracks/${this.$route.params.track_id}/settings/events`,
        params,
      });

      events.page = page;
      events.count = data.count;
      events.list = data.results;
      // if no filters
      if (!("event_ids" in params)) {
        this.eventsTotal = data.count;
      }
      // this.eventsTotal = data.count;
      this.$store.dispatch(
        "talent/getEventsByIds",
        data.results.map((n) => n.event_id)
      );
    },
    async getOldPenalty() {
      this.penaltyPending = true;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/tracks/${this.track?.id}/settings/old_penalty`,
        });
        this.oldPenalty = data;
      } catch (error) {
        if (error.status !== 404) {
          this.$toast(
            `Не удалось получить настройки устаревания. ${error.message}`,
            { type: "error" }
          );
        }
      }
      this.penaltyPending = false;
    },
    async getEventTrackInfo() {
      await this.$store.dispatch("talent/getAllAchievementRoles");
      await this.getEvents();
      await this.getOldPenalty();
    },
    async handleSavePenalty(penaltyData) {
      if (this.penaltyPending) return;
      this.penaltyPending = true;
      // eslint-disable-next-line no-unused-vars
      const { id, track_id, ...payload } = penaltyData;
      const config = {
        method: "POST",
        url: `/tracks/${this.track?.id}/settings/old_penalty`,
        data: payload,
      };
      if (id) {
        config.method = "PUT";
      }
      try {
        const { data } = await apiClient(config);
        this.oldPenalty = data;
        this.$toast("Настройки устаревания успешно сохранены.", {
          type: "success",
        });
      } catch (error) {
        this.$toast(
          `не удалось сохранить настройки устаревания. ${error.message}`,
          {
            type: "error",
          }
        );
      }
      this.penaltyPending = false;
    },
    async handleDeleteSetting(setting) {
      const confirm = await this.$root.$confirm(
        "Удалить настройку мероприятия",
        `Вы уверены что хотите удалить мероприятие <strong>#${setting.event_id}</strong> из этого трека?`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
          confirmColor: "error",
        }
      );
      if (!confirm) return;
      try {
        await apiClient.delete(
          `/tracks/${this.track?.id}/settings/events/${setting.id}`
        );
        this.getEvents(this.events.page);
        this.$toast(`Настройки мероприятия №${setting.event_id} удалены.`, {
          type: "success",
        });
      } catch (error) {
        this.$toast(
          `Не удалось удалить настройки мероприятия ${setting.event_id}. ${error.message}`,
          { type: "error" }
        );
      }
    },
    handleOpenEventSettings(setting, eventId) {
      this.currentSetting = setting;
      this.selectedEventId = eventId;
      this.addEventDialog = true;
    },
    handleCopyEventSettings(setting) {
      if (!setting) return;
      const newSettings = {
        outcome: setting.outcome,
        score: setting.score,
        max_score: setting.max_score,
      };
      this.currentSetting = newSettings;
      this.addEventDialog = true;
    },
    async handleSearch() {
      const query = this.search ? this.search.trim() : undefined;
      console.log(query, "query");
      if (!query) {
        this.event_ids = undefined;
        this.getEvents(1);
        return;
      }
      this.searchLoading = true;
      if (isNaN(Number(query))) {
        const { data } = await talentClient({
          url: "/events/",
          method: "GET",
          params: {
            offset: 0,
            limit: this.events.size,
            competences: this.track?.competence_id,
            search: query,
          },
        });
        if (!data.results?.length) {
          this.events.list = [];
          this.events.page = 1;
          this.events.count = 0;
          this.searchLoading = false;
          return;
        }
        this.event_ids = data.results?.map((n) => n.id).join(",");
      } else {
        this.event_ids = query;
      }
      this.searchLoading = false;
      await this.getEvents(1);
    },

    handleSort(field) {
      if (this.ordering === field) {
        this.ordering = `-${field}`;
      } else {
        this.ordering = field;
      }
      this.getEvents(1);
    },
  },
};
</script>

<style></style>

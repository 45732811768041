<template>
  <v-sheet class="pa-4">
    <div class="d-flex">
      <div class="flex-grow-1 mr-2">
        <div>
          <a
            :href="talentUrl"
            target="talent_event"
            class="link title-link"
            title="Перейти к мероприятию в админку таланта"
            >#{{ settings.event_id }}&nbsp;<span style="color: #dedede">|</span
            >&nbsp;{{ title }}</a
          >
          <div v-if="!event" class="error--text">
            <v-icon size="1em" color="error">mdi-alert</v-icon>&nbsp;Нет данных
            о мероприятии
          </div>
        </div>
        <div>Базовый балл за достижение: {{ settings.score }}</div>
        <div v-if="settings.max_score">
          Максимальный балл: {{ settings.max_score }}
        </div>
        <div v-if="isTestingTrack">
          Дата начала мероприятия: {{ eventStart }}
        </div>
      </div>
      <div class="flex-grow-0 flex-shrink-0">
        <slot name="controls"></slot>
      </div>
    </div>
    <div v-if="isTestingTrack">
      <p class="mt-2">Балл за мероприятие будет расчитан по формуле:</p>
      <div class="code">
        Scores = (Achievement.score *
        <span title="Базовый балл за мероприятие" class="code-value">{{
          settings.score
        }}</span>
        *
        <span title="Коэффициент устаревания" class="code-value">{{
          eventPenalty
        }}</span
        >)/<span class="code-value" title="Максимальный балл за мероприятие">{{
          settings.max_score
        }}</span>
      </div>
    </div>
    <div v-else class="role-table">
      <table>
        <thead>
          <tr>
            <th width="90">Роль:</th>
            <th v-for="item in outcomes" :key="item.id">
              {{ item.roleName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="90">Коэфф.:</td>
            <td
              v-for="item in outcomes"
              :key="item.id"
              :title="
                !item.isExist
                  ? `У мероприятия отсутствует роль достижения: ${item.roleName}`
                  : item.isDefault
                  ? 'Для этой роли используется коэффициент по-умолчанию = 1'
                  : ''
              "
            >
              <span v-if="!item.isExist"
                ><v-icon size="1em" color="warning">mdi-alert</v-icon></span
              >
              <span v-if="item.isDefault"
                ><v-icon size="1em" color="deep-purple"
                  >mdi-auto-fix</v-icon
                ></span
              >
              {{ item.coefficient }}
            </td>
          </tr>
          <tr v-if="settings && 'score' in settings">
            <td width="90">Баллы:</td>
            <td v-for="item in outcomes" :key="item.id">
              {{ item.computedScore }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-4 text-right">
      <a
        :href="`${VUE_APP_TALENT_HOST}/admin/package/achievement/?event__pk__exact=${settings.event_id}`"
        class="link"
        target="talent_achievements"
        >Достижения за мероприятие</a
      >
    </div>
  </v-sheet>
</template>

<script>
import { DEFAULT_OUTCOME_COEF, TRACK_CODES } from "@/constants";
import dayjs from "@/plugins/dayjs";
const getOldPenatyValue = (penalty, eventStartYear) => {
  console.log(penalty, eventStartYear);
  if (!penalty || !eventStartYear) {
    return 1;
  }
  const { one, two, three, four, five, other } = penalty;
  const year = new Date().getFullYear();
  const diff = year - new Date(eventStartYear).getFullYear();

  let result = 1;
  switch (diff) {
    case 1:
    case 0:
      result = one;
      break;
    case 2:
      result = two;
      break;
    case 3:
      result = three;
      break;
    case 4:
      result = four;
      break;
    case 5:
      result = five;
      break;
    default:
      result = other;
  }
  return result ?? 1;
};

export default {
  name: "EventSettingsCard",
  components: {},
  props: {
    settings: {
      type: Object,
    },
    track: {
      type: Object,
    },
    oldPenalty: {
      type: Object,
    },
  },
  computed: {
    events() {
      return this.$store.state.talent.events;
    },
    isTestingTrack() {
      console.log(this.oldPenalty);
      return this.track?.track?.code === TRACK_CODES.TESTING;
    },
    achievementRoles() {
      return this.$store.state.talent.achievementRoles.reduce((acc, role) => {
        acc[role.id] = role;
        return acc;
      }, {});
    },
    outcomes() {
      const { achievementRoles, settings, events } = this;
      const outcomes = settings?.outcome || [];
      const eventRoles = events[settings.event_id]?.achievement_roles || [];
      const outcomesRoleIds = outcomes.map((n) => n.role_id);
      const eventRoleIds = eventRoles.map((n) => n.id);

      /**
       * Коэффициенты по умолчанию для ролей достижений
       * мероприятия которые заданы в таланте для этого ивента
       */
      const defaultOutcomes = eventRoles
        .filter((n) => !outcomesRoleIds.includes(n.id))
        .map((n) => {
          return {
            role_id: n.id,
            coefficient: DEFAULT_OUTCOME_COEF,
            id: `default-${n.id}`,
            roleName: n.name,
            isDefault: true,
            isExist: true,
            computedScore:
              !!settings.score &&
              Number((settings.score * DEFAULT_OUTCOME_COEF).toFixed(3)),
          };
        });

      const settingsOutcomes = outcomes.map((item) => {
        return {
          ...item,
          roleName:
            achievementRoles[item.role_id]?.name || `Роль №${item.role_id}`,
          isExist: eventRoleIds.includes(item.role_id),
          isDefault: false,
          computedScore:
            !!settings.score &&
            Number((settings.score * item.coefficient).toFixed(3)),
        };
      });

      return [...settingsOutcomes, ...defaultOutcomes]?.sort((a, b) => {
        return b.role_id - a.role_id;
      });
    },
    event() {
      const { settings, events } = this;
      return events[settings?.event_id];
    },
    eventStart() {
      return this.event?.start
        ? dayjs(this.event.start).format("DD.MM.YYYY")
        : "нет данных";
    },
    title() {
      const { event } = this;
      return event?.title || `Мероприятие №${this.settings?.event_id}`;
    },
    talentUrl() {
      return (
        process.env.VUE_APP_TALENT_HOST +
        `/admin/package/event/${this.settings?.event_id}/change/`
      );
    },
    eventPenalty() {
      return getOldPenatyValue(this.oldPenalty, this.event?.start);
    },
  },
  created() {
    this.VUE_APP_TALENT_HOST = process.env.VUE_APP_TALENT_HOST;
  },
};
</script>

<style lang="scss" scoped>
.code {
  background-color: #dedede;
  font-family: monospace;
  padding: 20px;
  border-radius: 12px;
}
.code-value {
  display: inline-block;
  vertical-align: middle;
  padding: 2px 8px;
  background-color: #000;
  color: #fff;
  font-weight: bold;
  border-radius: 6px;
}
.link {
  text-decoration: none;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.6;
  }
}

.title-link {
  font-size: 16px;
  font-weight: 500;
}
.role-table {
  overflow-x: auto;
  margin-top: 10px;

  table {
    min-width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
      font-weight: 500;
      padding: 5px 10px;
      border: 1px solid #f3f8fb;

      &:nth-child(odd) {
        background-color: #f3f8fb;
      }

      &:first-child {
        font-weight: normal;
      }
    }

    td {
      border: 1px solid #f3f8fb;
      padding: 5px 10px;

      &:nth-child(odd) {
        background-color: #f3f8fb;
      }
    }
  }
}
</style>
